/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Image } from '~/types/miscellaneous';
import cloneDeep from 'clone-deep';
import { RichTextSchema } from '@storyblok/js';
import { InlineButton } from '~/components/page-building/InlineRichtextComponents';
import LinkType from '~/types/LinkType';

const imageFromStoryblokAsset = (asset?: any): Image => {
    if (!asset || !asset.filename) {
        return { src: '/images/image-placeholder.svg', alt: '' };
    }

    return {
        src: asset.filename,
        alt: asset.alt,
    };
};

const isLivePreview = () => {
    const route = useRoute();
    return Object.keys(route.query).includes('_storyblok');
};

const richTextToHtml = (content?: any) => {
    if (!content) {
        return null;
    }

    const mySchema = cloneDeep(RichTextSchema);

    const html = renderRichText(content, {
        schema: mySchema,
        resolver: (component, blok) => {
            switch (component) {
                case 'inline-button': {
                    const url =
                        blok.link.linktype === 'story'
                            ? '/' + blok.link.cached_url
                            : blok.link.url;
                    const target =
                        blok.link.linktype === 'url' ? '_blank' : '_self';
                    const link = {
                        label: blok.label,
                        style: blok.style,
                        url: url,
                        target: target,
                    } as LinkType;
                    return InlineButton(link);
                    break;
                }
                default:
                    return `Component ${component} not found`;
            }
        },
    });

    if (html === '<p></p>') {
        return null;
    }

    return html;
};

export { imageFromStoryblokAsset, isLivePreview, richTextToHtml };
